$base-color: #fba508;
$text-color: #2e3b44;
$secondary-color: #054a91;
$white: #f9f8f7;
$body-font: 'Roboto', sans-serif;
$heading-font: 'Anton', sans-serif;

.burger-menu {
  font-family: $body-font;
  text-transform: uppercase;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 25px;
  .toggler {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 50px;
    height: 50px;
    opacity: 0;
    &:checked + .hamburger > div {
      transform: rotate(135deg);
      background: white;
    }
    &:checked + .hamburger > div:before,
    &:checked + .hamburger > div:after {
      top: 0;
      transform: rotate(90deg);
    }
    &:checked:hover + .hamburger > div {
      transform: rotate(225deg);
    }
    &:checked ~ .menu {
      visibility: visible;
    }
    &:checked ~ .menu > div {
      transform: scale(1.2);
      transition-duration: 0.75s;
    }
    &:checked ~ .menu > div > div {
      opacity: 1;
      transition: opacity 0.4s ease 0.4s;
    }
  }
  .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 30px;
    height: 30px;
    padding: 1rem;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      position: relative;
      flex: none;
      width: 100%;
      height: 4px;
      background: $base-color;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease;
    }
    & > div::before,
    & > div::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: -10px;
      width: 100%;
      height: 4px;
      background: inherit;
    }
    & > div::after {
      top: 10px;
    }
  }
}
.burger-menu .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.burger-menu .menu > div {
  background: #f57322c5;
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
}

.burger-menu .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.burger-menu .menu > div > div > ul > li {
  list-style: none;
  color: #fff;
  font-size: 2rem;
  padding: 1rem;
  margin-right: 70px;
}

.burger-menu .menu > div > div > ul > li > a {
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}

@media only screen and (max-width: 850px) {
  .burger-menu {
    display: block;
  }
}
