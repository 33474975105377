$base-color: #fba508;
$text-color: #2e3b44;
$secondary-color: #054a91;
$white: #f9f8f7;
$body-font: 'Roboto', sans-serif;
$heading-font: 'Anton', sans-serif;

.landing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .hero {
    width: 100%;
    margin-bottom: 60px;
    .image {
      width: 100%;
      overflow: hidden;
      img {
        clip-path: polygon(100% 0, 100% 46%, 26% 74%, 0 63%, 0 0);
        object-fit: cover;
        height: 475px;
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        z-index: -20;
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      .left {
        width: 30%;
        p {
          color: $white;
          font-family: $body-font;
          background-color: #fba60891;
          padding: 2px 10px;
          border-radius: 15px;
          font-size: 1.2rem;
          font-weight: 700;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 120px;
        border-radius: 15px;
        h1 {
          font-family: $heading-font;
          color: #24201f;
          font-size: 5rem;
          margin-top: 30px;
          span {
            color: $base-color;
          }
        }
        h3 {
          font-family: $body-font;
          font-weight: 400;
          font-size: 2.7rem;
          color: $white;
          margin-left: 20px;
          margin-top: 0;
          background-color: rgba(238, 238, 238, 0.281);
          border-radius: 15px;
          padding: 10px;
          text-align: center;
        }
      }
    }
  }
  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 110%;
    margin-top: 15px;
    .image {
      img {
        height: 250px;
      }
    }
    .content {
      width: 80%;
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 240px;
        padding: 0 10px;
        font-family: $body-font;
        h4 {
          font-size: 1.6rem;
          margin-bottom: 5px;
          font-weight: 700;
        }
        p {
          text-align: center;
          font-size: 1.15rem;
          font-weight: 300;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .content {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 70px !important;
    .left {
      width: 60% !important;
      p {
        text-align: center;
        background-color: #fba608c7 !important;
        font-size: 1rem !important;
      }
    }
    .right {
      justify-content: center;
      padding: 0 !important;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 3.6rem !important;
        display: none;
      }
      h3 {
        font-size: 2.3rem !important;
        margin: 0 !important;
        padding: 0 !important;
        background-color: rgba(238, 238, 238, 0.438) !important;
        letter-spacing: 2.5px;
      }
    }
  }

  .body {
    flex-direction: column;
    .image {
      img {
        display: none;
      }
    }
    .content {
      align-items: center !important;
      p {
        text-align: justify !important;
        letter-spacing: 1px;
      }
    }
  }
}
