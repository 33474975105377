$base-color: #fba508;
$text-color: #2e3b44;
$secondary-color: #054a91;
$white: #f9f8f7;
$body-font: 'Roboto', sans-serif;
$heading-font: 'Anton', sans-serif;

.projects {
  .heading {
    h1 {
      text-transform: uppercase;
      font-family: $heading-font;
      text-align: center;
      letter-spacing: 8px;
      span {
        color: $base-color;
      }
    }
    button {
      background-color: $base-color;
      padding: 5px 10px;
      border-radius: 15px;
      color: $white;
      text-transform: uppercase;
      border: none;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
  }
  .customers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    h2 {
      text-transform: uppercase;
      font-size: 3rem;
      font-family: $body-font;
      span {
        font-weight: 300;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .heading {
    h1 {
      font-size: 1.9rem !important;
      letter-spacing: 2px !important;
    }
    button {
      img {
        width: 10 !important;
        height: 10 !important;
        margin-right: 10px;
      }
    }
  }
  .customers {
    h2 {
      font-size: 1.8rem !important;
      text-align: center;
    }
    img {
      width: 120%;
    }
  }
}
