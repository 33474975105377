$text-color: #2e3b44;
$secondary-color: #054a91;
$color_white: #f9f8f7;
$body-font: 'Roboto', sans-serif;
$heading-font: 'Anton', sans-serif;

$color_prime: #fba508;
$color_grey: #e2e2e2;
$color_grey_dark: #a2a2a2;

.project-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: -20px;
  width: 100%;
  box-shadow: 0 3px 7px -1px rgba(#000, 0.1);
  margin-bottom: 1.6%;
  background: $color_white;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 15px;
  overflow: hidden;
  z-index: 0;
  a {
    color: inherit;
    &:hover {
      color: $color_prime;
    }
  }
  &:hover {
    .photo {
      transform: scale(1.3) rotate(3deg);
    }
  }
  .meta {
    position: relative;
    z-index: 0;
    height: 200px;
  }
  .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s;
  }
  .description {
    padding: 1rem;
    background: $color_white;
    position: relative;
    z-index: 1;
    h1 {
      line-height: 1;
      margin: 0;
      font-size: 1.6rem;
      text-transform: uppercase;
      font-family: $body-font;
      font-weight: 300;
      color: $secondary-color;
      letter-spacing: 10px;
    }
  }
  p {
    position: relative;
    margin: 1rem 0 0;
    font-family: $body-font;
    &:first-of-type {
      margin-top: 1.25rem;
      &:before {
        content: '';
        position: absolute;
        height: 2px;
        background: $color_prime;
        width: 55px;
        top: -0.75rem;
        border-radius: 3px;
      }
    }
  }

  @media (min-width: 640px) {
    flex-direction: row;
    // max-width: 700px;
    min-height: 221px;
    .meta {
      flex-basis: 40%;
      height: auto;
    }
    .description {
      flex-basis: 80%;
      &:before {
        transform: skewX(-3deg);
        content: '';
        background: $color_white;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    &.alt {
      flex-direction: row-reverse;
      margin-left: 20px;
      .description {
        &:before {
          left: inherit;
          right: -10px;
          transform: skew(3deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .project-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
