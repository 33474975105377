$base-color: #fba508;
$body-font: 'Roboto', sans-serif;

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .footer {
    background-color: #fba508;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-family: $body-font;
    width: 100%;
    .brand {
      flex: 1;
      display: flex;
      justify-content: center;
      span {
        font-size: 0.8rem;
        margin: 0;
      }
    }
    .made-by {
      display: flex;
      align-items: center;
      margin-right: 10px;
      span {
        padding-right: 5px;
        font-size: 0.8rem;
      }
      a {
        line-height: 0px;
      }
      img {
        width: 23px;
        padding: 0;
        margin: 0;
      }
    }
  }
  .content-body {
    width: 80%;
    flex: 1 0 auto;
    margin: 0 auto;
  }
}
