$base-color: #fba508;
$text-color: #2e3b44;
$secondary-color: #054a91;
$white: #f9f8f7;
$body-font: 'Roboto', sans-serif;
$heading-font: 'Anton', sans-serif;

.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  font-family: $body-font;
  .heading {
    h2 {
      text-transform: uppercase;
      font-size: 2.2rem;
      font-family: $body-font;
      margin-top: 0;
      .logo {
        color: $base-color;
      }
      .first {
        font-weight: 300;
      }
    }
  }
  .bottom {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    .form {
      padding: 0 70px;
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;
      .title {
        h3 {
          font-weight: 300;
          text-transform: uppercase;
          font-size: 1.5rem;
        }
        p {
          font-weight: 300;
          font-size: 1rem;
          width: 100%;
        }
      }
      form {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        input {
          width: 95%;
          padding: 10px 10px;
          border: 2px solid rgb(182, 180, 180);
          border-radius: 10px;
          margin: 8px 0;
          &::placeholder {
            font-size: 1rem;
            color: rgb(182, 180, 180);
            text-transform: capitalize;
            font-style: italic;
          }
        }
        textarea {
          resize: none;
          border: 2px solid rgb(182, 180, 180);
          height: 189px;
          width: 95%;
          border-radius: 10px;
          padding: 10px 10px;
          margin: 8px 0;
          &::placeholder {
            font-size: 1rem;
            color: rgb(182, 180, 180);
            text-transform: capitalize;
            font-style: italic;
          }
        }
        button {
          background-color: $base-color;
          padding: 10px 30px;
          border-radius: 15px;
          color: $white;
          text-transform: uppercase;
          align-self: flex-end;
          border: none;
        }
      }
    }
  }
  .contact-info {
    padding: 0 70px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;

    .title {
      h3 {
        font-weight: 300;
        text-transform: uppercase;
        font-size: 1.5rem;
      }
      p {
        font-weight: 300;
        font-size: 1rem;
        width: 100%;
      }
    }
    .info {
      margin-top: 20px;
      .mobile {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .num {
          p {
            margin: 0;
            margin-left: 5px;
          }
        }
      }
      .email {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        .email-e {
          p {
            margin: 0;
            margin-left: 7px;
            margin-bottom: 20px;
          }
        }
        .icon {
          img {
            width: 36px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .contact {
    width: 100% !important;
  }
  .heading {
    h2 {
      font-size: 1.8rem !important;
      margin-top: 20px !important;
    }
  }
  .bottom {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    .form {
      padding: 0 !important;
      form {
        align-items: center !important;
        justify-content: center !important;
        input {
          &::placeholder {
            font-size: 0.9rem !important;
          }
        }
      }
    }
  }
  .contact-info {
    width: 100% !important;
    padding: 0 !important;
    justify-content: center !important;
    .email {
      .email-e {
        p {
          margin-top: 10px !important;
        }
      }
      .icon {
        img {
          width: 30px !important;
        }
      }
    }
  }
}
